import { Login } from "./login/Login";
import { Logout } from "./logout/Logout";
import { LogoutSilent } from "./logout/LogoutSilent";
import { PasswordChange } from "./passwordChange/PasswordChange";

export const Auth = {
    Login,
    Logout,
    LogoutSilent,
    PasswordChange
};
