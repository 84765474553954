import { Switch } from "react-router";
import * as Sentry from "@sentry/react";
import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { Route } from "src/config/routes";
import { PrivateRoute } from "src/components/routes/PrivateRoutes";
import { PublicRoute } from "src/components/routes/PublicRoutes";
import { Header } from "src/components/header";
import { Auth } from "src/screens/auth";
import { CustomerRoutes } from "src/screens/dashboard/customers/customer/CustomerRoutes";

export const RoutesBase = () => {
    const { uiStore } = useContext(RootStoreContext);

    return (
        <main className="d-flex flex-column" style={{ minHeight: "100vh", background: uiStore.backgroundColor }}>
            <Header.Appheader />
            <div className="flex-grow-1 d-flex flex-column" style={{ marginTop: uiStore.headerHeight }}>
                <Switch>
                    <PublicRoute path={Route.logoutSilent} component={Auth.LogoutSilent} />
                    <PublicRoute path={Route.logout} component={Auth.Logout} />
                    <PrivateRoute path="/" component={CustomerRoutes} />
                </Switch>
            </div>
        </main>
    );
};

export const Routes = Sentry.withProfiler(observer(RoutesBase));
